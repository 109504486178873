function detectOutdatedBrowser() {
    // Check for Internet Explorer
    if (/MSIE|Trident/.test(window.navigator.userAgent)) {
        // Sentry.captureMessage("Outdated Browser Detected", Sentry.Severity.Warning);
        alert("This application does not support Internet Explorer. Please use a modern browser.");
    } // Check Chrome for outdated versions
    else if (window.navigator.userAgent.includes("Chrome")) {
        const aryMatches = window.navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
        const minVersion = 108;
        if (aryMatches && parseInt(aryMatches[2], 10) < minVersion) {
            const msg =
                `This application requires Chrome version ${minVersion} or higher. ` +
                `Would you like to download the latest version of Chrome?`;
            if (window.confirm(msg)) {
                window.location.href = "https://www.google.com/chrome/";
            }
        }
    }
    // Check Firefox for outdated versions
    else if (window.navigator.userAgent.includes("Firefox")) {
        const aryMatches = window.navigator.userAgent.match(/Firefox\/([0-9]+)\./);
        const minVersion = 100;
        if (aryMatches && parseInt(aryMatches[1], 10) < minVersion) {
            const msg =
                `This application requires Firefox version ${minVersion} or higher. ` +
                `Would you like to download the latest version of Firefox?`;
            if (window.confirm(msg)) {
                window.location.href = "https://www.mozilla.org/en-US/firefox/new/";
            }
        }
    } // Check Safari for outdated versions
    else if (window.navigator.userAgent.includes("Safari")) {
        const aryMatches = window.navigator.userAgent.match(/Version\/([0-9]+)\./);
        const minVersion = 15;
        if (aryMatches && parseInt(aryMatches[1], 10) < minVersion) {
            const msg =
                `This application requires Safari version ${minVersion} or higher. ` +
                `Would you like to download the latest version of Safari?`;
            if (window.confirm(msg)) {
                window.location.href = "https://www.apple.com/safari/";
            }
        }
    } // Check Edge for outdated versions
    else if (window.navigator.userAgent.includes("Edge")) {
        const aryMatches = window.navigator.userAgent.match(/Edge\/([0-9]+)\./);
        const minVersion = 100;
        if (aryMatches && parseInt(aryMatches[1], 10) < minVersion) {
            const msg =
                `This application requires Edge version ${minVersion} or higher. ` +
                `Would you like to download the latest version of Edge?`;
            if (window.confirm(msg)) {
                window.location.href = "https://www.microsoft.com/en-us/edge";
            }
        }
    }
}

detectOutdatedBrowser();
